// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"578bb88264e78bb1bdee5502af3a6f2996294152"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import {
  browserProfilingIntegration,
  feedbackIntegration,
  httpClientIntegration,
  init,
  replayIntegration,
} from "@sentry/nextjs";

// Routes that should not be sampled
const IGNORED_ROUTES = [
  "healthcheck",
  "usercentrics",
  "/_next/image:",
  "/static/",
  "/_next/static",
  "/favicon.ico",
  "/robots.txt",
];

interface SamplingContext {
  name: string;
  attributes?: Record<string, unknown>;
  parentSampled?: boolean;
}

/**
 * Sentry trace sampler configuration
 * Determines sampling rates for different types of transactions
 */
const tracesSampler = ({ name, parentSampled }: SamplingContext): number => {
  // Check if the transaction name matches any ignored routes
  const shouldIgnore = IGNORED_ROUTES.some((route) =>
    name.toLowerCase().includes(route.toLowerCase()),
  );

  if (shouldIgnore) {
    return 0; // Drop these transactions
  }

  // Respect parent sampling decision if available
  if (typeof parentSampled === "boolean") {
    return parentSampled ? 1 : 0;
  }

  // Use environment-specific sampling rates
  const isProduction = process.env.NEXT_PUBLIC_APP_ENV === "production";
  const defaultSampleRate = isProduction ? 0.1 : 0;

  // Apply different sampling rates based on transaction type
  if (name.startsWith("/api/")) {
    return isProduction ? 1 : defaultSampleRate; // Higher sampling for API routes
  }

  if (name.includes("error") || name.includes("exception")) {
    return 1; // Always sample error-related transactions
  }

  // Default sampling rate for everything else
  return defaultSampleRate;
};

init({
  dsn: "https://23a62f3cbbab4492b50d46d3f4dc306c@o4505606794379264.ingest.sentry.io/4505477061607424",

  environment: process.env.NEXT_PUBLIC_APP_ENV,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLED === "true",

  // These are replaced by the tracesSampler function
  tracesSampleRate: process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 0,
  profilesSampleRate:
    process.env.NEXT_PUBLIC_APP_ENV === "production" ? 0.1 : 0,

  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api.complicated.life/,
    /^https:\/\/api.staging.complicated.life/,
  ],

  tracesSampler,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,

  replaysOnErrorSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    httpClientIntegration(),
    feedbackIntegration({ autoInject: false }),
    browserProfilingIntegration(),
  ],

  ignoreErrors: [
    "AbortError",
    "globalThis",
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    // getstream token errors: https://github.com/GetStream/stream-chat-js/blob/93c4e983a825f668c8371b458badf6cc655845f1/src/connection.ts#L522
    "WS failed with code",
    // https://issuetracker.google.com/issues/396043331
    "Can't find variable: gmo",
    "Failed to load resource: the server responded with a status of 404 ()",
    /WS failed with code \d+ and reason - .*/,
  ],
});
